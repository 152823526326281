export {}

declare global {
  interface WeakRef<T extends object> {
    deref(): T | undefined
  }

  var WeakRef:
    | {
        new <T extends object>(target: T): WeakRef<T>
      }
    | undefined
}

if (typeof WeakRef === "undefined") {
  class WeakRef<T extends object> {
    private target: T
    constructor(target: T) {
      this.target = target
    }

    deref(): T | undefined {
      return this.target
    }
  }
  ;(window as any).WeakRef = WeakRef
}
